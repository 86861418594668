
   ::v-deep .quality-course-container {
        overflow-x: hidden;
    }
    .login-container {
        /*width: 1200px;*/
        /*min-width: 1200px;*/
        margin: 0 auto;
        display: flex;
        min-height: calc(100% - 84px);
        justify-content: center;
        align-items: center;
        position: relative;
        .login-photo {
            position: absolute;
            background: url("../../assets/images/login-bg-teacher.png") no-repeat 0 0;
            background-size:  100% 100%;
            width: 1200px;
            height: 100%;
            max-width: 1200px;
        }
        .login-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 392px;
            width: 500px;
            background-color: #fff;
            box-shadow:0 2px 18px 0 rgba(126,126,131,0.2);
            border-radius:4px;
            z-index: 9;
            .login-title {
                text-align: center;
                .greetings {
                    font-size: 28px;
                    margin: 0 0 1px;
                }
            }
            .login-content {
                width: 336px;
                margin: 0 auto;
               .el-form-item:last-child {
                    margin-top: 28px;
                    margin-bottom: 0;
                }
                ::v-deep .el-form-item__label {
                    padding: 0;
                }
                ::v-deep .el-input {
                    .el-input__inner {
                        height: 45px;
                        line-height: 43px;
                        border-radius: 0;
                        padding: 0;
                        background-color: transparent !important;
                        border-bottom: 1px solid rgba(0,123,255,.25);
                        border-left: none;
                        border-right: none;
                        border-top: none;
                        &:focus {
                            color: #495057;
                            background-color: #fff;
                            outline: none;
                            border-bottom: 1px solid #4A90E2;
                            box-shadow: none;
                        }
                    }
                    .el-input__prefix {
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        transform: translateY(-50%);
                        display: flex;
                        align-items: center;
                        .iconfont {
                            font-size: 20px;
                        }
                    }
                }
                .submit-btn {
                    width: 100%;
                    font-size: 16px;
                    height: 45px;
                    border-radius: 0;
                    border: none;
                    background: linear-gradient(to bottom right, #F35552 , #E31D1A);
                }
            }
        }
    }
